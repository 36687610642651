<template>
    <ContentField v-if="!$store.state.user.pulling_info">
        <div>
            <el-row>
                <el-col :span="8">
                    <div class="grid-content ep-bg-purple" />
                </el-col>
                <el-col :span="8">
                    <div class="grid-content ep-bg-purple-light">
                        <form @submit.prevent="login">
                            <div class="mb-3">
                                <label for="username" class="form-label">用户名</label>
                                <input v-model="username" type="text" class="form-control" id="username"
                                    placeholder="请输入用户名">
                            </div>
                            <div class="mb-3">
                                <label for="password" class="form-label">密码</label>
                                <input v-model="password" type="password" class="form-control" id="password"
                                    placeholder="请输入密码">
                            </div>
                            <div class="error_message">{{ error_message }}</div>
                            <button type="submit" class="btn btn-primary">登录</button>
                        </form>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content ep-bg-purple" />
                </el-col>
            </el-row>
        </div>
    </ContentField>
</template>
<script setup>
import ContentField from '@/components/ContentField.vue'
import { useStore } from 'vuex';
import { ref } from 'vue';
import router from '@/router/index';

const store = useStore();
let username = ref('');
let password = ref('');
let error_message = ref('');

const login = () => {
    error_message.value = '';
    store.dispatch("login", {
        username: username.value,
        password: password.value,
        success() {
            store.dispatch("getinfo", {
                success() {
                    router.push({ name: 'home' });
                    console.log(store.state.user);
                },
                error(resp) {
                    console.log(resp)
                }
            })
        },
        error() {
            error_message.value = '用户名密码错误';
        }
    })
}


const jwt_token = localStorage.getItem("jwt_token");
if (jwt_token) {
    store.commit("updateToken", jwt_token);
    store.dispatch("getinfo", {
        success() {
            router.push({ name: "home" })
        },
        error() {
            store.commit("updatePullingInfo", false);
        }
    })
} else {
    store.commit("updatePullingInfo", false);
}


</script>
<style scoped lang="scss">
button {
    width: 100%;
}

.el-row {
    margin-bottom: 20px;
}

.el-row:last-child {
    margin-bottom: 0;
}

.el-col {
    border-radius: 4px;
}

input {
    width: 250px;
}

.grid-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    min-height: 36px;
}

.error_message {
    color: red;
}
</style>