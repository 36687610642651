<template>
    <div ref="parent" class="gamemap">
        <canvas ref="canvas" tabindex="0"></canvas> <!-- tabindex="0" 就可以输入键盘操作了  -->
    </div>
</template>
<script setup>
import { GameMap } from '@/assets/scripts/GameMaps';
import { ref, onMounted } from 'vue'

const parent = ref(null);
const canvas = ref(null);

onMounted(() => {
    new GameMap(canvas.value.getContext('2d'), parent.value)
});

</script>
<style scoped>
div.gamemap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
}
</style>